import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import Consent from "../Pages/consent/consent";
import PromptNoneConsent from "../Pages/consent/promptNoneConsent";
import Register from "../Pages/registration/register";
import RegisterWithPhone from "../Pages/registration/registerWithPhone";
import SignInWithEmail from "../Pages/signIn/signInWithEmail";

// ConsentRoute decides and returns a consent component based on prompt type.
function ConsentRoute() {
  const [response, setResponse] = useState({});
  const { setAuth, setRedirectUri } = useAuth();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let location = useLocation();

  // UseEffect
  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const response = await axios.get("/refreshToken", {
          withCredentials: true,
        });
        setResponse((prev) => {
          prev = response;
          return prev;
        });
        setAuth(response?.data?.data);
      } catch (error) {
        setResponse((prev) => {
          prev = error.response;
          return prev;
        });
        setAuth({});
      }
    };
    getAccessToken();
  }, [setAuth]);

  // Check if response is ready
  if (response?.status) {
    setRedirectUri(location);
    if (response?.status === 200) {
      if (params.prompt === "none") {
        if (response.data?.data?.access_token)
          return (
            <PromptNoneConsent consentId={params.consentId} loggedIn={true} />
          );
        else
          return (
            <PromptNoneConsent consentId={params.consentId} loggedIn={false} />
          );
      } else if (params.prompt === "register") {
        if (response.data?.data?.access_token)
          return <RegisterWithPhone consentId={params.consentId} />;
        else return <Register consentId={params.consentId} />;
        // } else if (params.prompt === "email") {
        //   if (response.data?.data?.access_token)
        //     return (
        //       <Consent consentId={params.consentId} />
        //     );
        //   else
        //     return (
        //       <SignInWithEmail consentId={params.consentId} />
        //     );
      } else {
        if (!response.data?.data?.access_token)
          return <Navigate to="/login" state={{ from: location }} replace />;
        else return <Consent consentId={params.consentId} />;
      }
    } else {
      if (params.prompt === "none")
        return (
          <PromptNoneConsent consentId={params.consentId} loggedIn={false} />
        );
      else if (params.prompt === "register")
        return <Register consentId={params.consentId} />;
      else if (params.prompt === "email")
        //   return <SignInWithEmail consentId={params.consentId} />;
        // else
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
  } else
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          minHeight: "100vh",
        }}
      >
        <CircularProgress
          size={40}
          sx={{
            color: "secondary.main",
            marginTop: "-12px",
            marginLeft: "-12px",
            my: 1,
          }}
        />
        <Typography>Please wait...</Typography>
      </Box>
    );
}

export default ConsentRoute;
